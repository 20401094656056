/** @format */

import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { toast } from "react-toastify";

import TextField from "../common/TextField";
import useGlobalContext from "../../hooks/useGlobalContext";
import CardField from "../common/CardField";
import validatePhysicalInjury from "../../validation/validatePhysicalInjury";

const PhysicalInjury = ({ currentSection, setCurrentSection }) => {
  const { physicalInjury, setPhysicalInjury } = useGlobalContext();

  const [errors, setErrors] = useState({});

  const [physicalInjuryValue, setPhysicalInjuryValue] = useState({
    firstSymptomsNA: false,
    firstSymptoms: "",
    firstTreatmentNA: false,
    firstTreatment: "",
    restYourTreatmentNA: false,
    restYourTreatment: "",
    doctorsListNA: false,
    doctorsList: "",
    receivedSurgery: "",
    surgeryListNA: false,
    surgeryList: "",
    medicationListNA: false,
    medicationList: "",
    treatmentsHelped: "",
    stillWorking: "",
    leavingReasonNA: false,
    leavingReason: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setPhysicalInjuryValue(physicalInjury);
  }, [physicalInjury]);

  const receivedSurgeryOptions = [
    { label: "Yes", value: "Yes", name: "receivedSurgeryYes" },
    { label: "No", value: "No", name: "receivedSurgeryNo" },
  ];

  const stillWorkingOptions = [
    { label: "Yes", value: "Yes", name: "stillWorkingYes" },
    { label: "No", value: "No", name: "stillWorkingNo" },
  ];

  const treatmentsHelpedOptions = [
    { label: "Yes", value: "Yes", name: "treatmentsHelpedOptionsYes" },
    { label: "No", value: "No", name: "treatmentsHelpedOptionsNo" },
    { label: "N/A", value: "N/A", name: "treatmentsHelpedOptionsN/A" },
  ];

  const handleChange = (event) => {
    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleReceivedSurgeryChange = (event) => {
    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      receivedSurgery: event.target.value,
    });
  };

  const handleTreatmentsHelpedChange = (event) => {
    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      treatmentsHelped: event.target.value,
    });
  };

  const handleStillWorkingChange = (event) => {
    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      stillWorking: event.target.value,
    });
  };

  const handleFirstSymptomsChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      firstSymptomsNA: isChecked,
      firstSymptoms: ""
    })
  };

  const handleFirstTreatmentChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      firstTreatmentNA: isChecked,
      firstTreatment: ""
    })
  };

  const handleRestYourTreatmentChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      restYourTreatmentNA: isChecked,
      restYourTreatment: ""
    })
  };

  const handleDoctorsListChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      doctorsListNA: isChecked,
      doctorsList: ""
    })
  };

  const handleSurgeryListChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      surgeryListNA: isChecked,
      surgeryList: ""
    })
  };

  const handleMedicationListChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      medicationListNA: isChecked,
      medicationList: ""
    })
  };

  const handleLeavingReasonChange = (event) => {
    const isChecked = event.target.checked;

    setPhysicalInjuryValue({
      ...physicalInjuryValue,
      leavingReasonNA: isChecked,
      leavingReason: ""
    })
  };

  const handleBackClick = (event) => {
    event.preventDefault();
    setPhysicalInjury(physicalInjuryValue);
    setCurrentSection(currentSection - 1);
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    // setPhysicalInjury(physicalInjuryValue);
    // setCurrentSection(currentSection + 1);

    const { isValid, errors } = validatePhysicalInjury(physicalInjuryValue);
    setErrors(errors);
    console.log("Physical Injury validation errors", errors);

    if (isValid) {
      setPhysicalInjury(physicalInjuryValue);
      setCurrentSection(currentSection + 1);
    } else {
      toast.error("Please fill in all fields correctly!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="mt-4">
      <p className="bg-green-400 w-[65%] mx-auto p-3 text-xl text-white rounded-lg mt-5 shadow-lg">
        Physical Injury
      </p>

      <form>
        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              41. If your injury was initially physical, describe the first symptoms (for example: pain) you experienced:
            </Typography>
            {physicalInjuryValue?.firstSymptomsNA !== true && <div>
              <TextField title="Answer" type="text" name="firstSymptoms"
                value={physicalInjuryValue?.firstSymptoms} placeholder="Your answer..."
                onChange={handleChange} error={errors.firstSymptoms} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="firstSymptomsNA" className="mr-1"
                  checked={physicalInjuryValue?.firstSymptomsNA} onChange={handleFirstSymptomsChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              42. If your injury was initially physical, describe the first treatment you received following this injury (emergency room, medical, chiropractic, physical therapy or PT, injections):
            </Typography>
            {physicalInjuryValue?.firstTreatmentNA !== true && <div>
              <TextField title="Answer" type="text" name="firstTreatment"
                value={physicalInjuryValue?.firstTreatment} placeholder="Your answer..."
                onChange={handleChange} error={errors.firstTreatment} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="firstTreatmentNA" className="mr-1"
                  checked={physicalInjuryValue?.firstTreatmentNA} onChange={handleFirstTreatmentChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              43. If your injury was initially physical, describe the rest of your treatment (medical, chiropractic, physical therapy)
            </Typography>
            {physicalInjuryValue?.restYourTreatmentNA !== true && <div>
              <TextField title="Answer" type="text" name="restYourTreatment"
                value={physicalInjuryValue?.restYourTreatment} placeholder="Your answer..."
                onChange={handleChange} error={errors.restYourTreatment} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="restYourTreatmentNA" className="mr-1"
                  checked={physicalInjuryValue?.restYourTreatmentNA} onChange={handleRestYourTreatmentChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              44. List the doctors you have seen for this physical injury:
            </Typography>
            {physicalInjuryValue?.doctorsListNA !== true && <div>
              <TextField title="Answer" type="text" name="doctorsList"
                value={physicalInjuryValue?.doctorsList} placeholder="Your answer..."
                onChange={handleChange} error={errors.doctorsList} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="doctorsListNA" className="mr-1"
                  checked={physicalInjuryValue?.doctorsListNA} onChange={handleDoctorsListChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <CardField
          title="45. Did you receive surgery for this injury?"
          type="radio"
          options={receivedSurgeryOptions}
          onChange={handleReceivedSurgeryChange}
          checked={physicalInjuryValue?.receivedSurgery}
          errors={errors.receivedSurgery}
        />

        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              46. List the surgeries you have received for this physical injury:
            </Typography>
            {physicalInjuryValue?.surgeryListNA !== true && <div>
              <TextField title="Answer" type="text" name="surgeryList"
                value={physicalInjuryValue?.surgeryList} placeholder="Your answer..."
                onChange={handleChange} error={errors.surgeryList} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="surgeryListNA" className="mr-1"
                  checked={physicalInjuryValue?.surgeryListNA} onChange={handleSurgeryListChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              47. List the medications you have received for this physical injury:
            </Typography>
            {physicalInjuryValue?.medicationListNA !== true && <div>
              <TextField title="Answer" type="text" name="medicationList"
                value={physicalInjuryValue?.medicationList} placeholder="Your answer..."
                onChange={handleChange} error={errors.medicationList} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="medicationListNA" className="mr-1"
                  checked={physicalInjuryValue?.medicationListNA} onChange={handleMedicationListChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <CardField
          title="48. Have any of the above treatments helped relieve your pain?"
          type="radio"
          options={treatmentsHelpedOptions}
          checked={physicalInjuryValue.treatmentsHelped}
          onChange={handleTreatmentsHelpedChange}
          error={errors.treatmentsHelped}
        />

        <CardField
          title="49. Are you still working?"
          type="radio"
          options={stillWorkingOptions}
          onChange={handleStillWorkingChange}
          checked={physicalInjuryValue?.stillWorking}
          errors={errors.stillWorking}
        />

        <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: "20px", textAlign: "left" }}>
              50. If you are not working, what is the reason you left work?
            </Typography>
            {physicalInjuryValue?.leavingReasonNA !== true && <div>
              <TextField title="Answer" type="text" name="leavingReason"
                value={physicalInjuryValue?.leavingReason} placeholder="Your answer..."
                onChange={handleChange} error={errors.leavingReason} widthStyle="auto" />
            </div>
            }
            <div className="mt-2">
              <label>
                <input type="checkbox" name="leavingReasonNA" className="mr-1"
                  checked={physicalInjuryValue?.leavingReasonNA} onChange={handleLeavingReasonChange} />
                N/A
              </label>
            </div>
          </CardContent>
        </Card>

        <div className="mx-auto w-[65%] flex justify-between mt-3">
          {currentSection > 0 && (
            <Button variant="contained" onClick={handleBackClick}>
              Back
            </Button>
          )}

          <Button variant="contained" type="button" onClick={handleNextClick}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PhysicalInjury;
