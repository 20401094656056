const ValidateDemoGraphic = (value) => {
  let isValid = true;
  let errors = {};

  if (!value.firstName.trim()) {
    errors.firstName = "See required fields above.";
    isValid = false;
  } else {
    errors.firstName = "";
  }

  if (!value.lastName.trim()) {
    errors.lastName = "See required fields above.";
    isValid = false;
  } else {
    errors.lastName = "";
  }

  const birthRegex = /^(?:19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!birthRegex.test(value.birth)) {
    errors.birth = "Invalid date of birth format";
    isValid = false;
  } else {
    errors.birth = "";
  }

  /**Made email not mandatory */
  // const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  // if (!value.email.trim()) {
  //   errors.email = "See required fields above.";
  //   isValid = false;
  // } else if (!emailRegex.test(value.email)) {
  //   errors.email = "Invalid email format";
  //   isValid = false;
  // } else {
  //   errors.email = "";
  // }

  /**Made phone number not mandatory */
  // const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  // // const phoneNumberRegex = /\([2-9][0-9]{2}\) [2-9][0-9]{2}-[0-9]{4}\b/;
  // if (!value.phoneNumber.trim()) {
  //   errors.phoneNumber = "See required fields above.";
  //   isValid = false;
  // } else if (!phoneNumberRegex.test(value.phoneNumber)) {
  //   errors.phoneNumber = "Invalid phone number format";
  //   isValid = false;
  // } else {
  //   errors.phoneNumber = "";
  // }

  if (value.checkedEthnicityItems.length === 0) {
    errors.checkedEthnicityItems = "See required fields above.";
    isValid = false;
  } else {
    errors.checkedEthnicityItems = "";
  }

  if (!value.radioSexItem.trim()) {
    errors.radioSexItem = "See required fields above.";
    isValid = false;
  } else if (value.radioSexItem === "female" && value.pregnant === null) {
    errors.pregnant = "See required fields above";
    isValid = false;
  } else {
    errors.radioSexItem = "";
    errors.pregnant = "";
  }

  if (!value.radioPreferPronounItem.trim()) {
    errors.radioPreferPronounItem = "See required fields above.";
    isValid = false;
  } else {
    errors.radioPreferPronounItem = "";
  }

  if (!value.maritalStatusItems.trim()) {
    errors.maritalStatusItems = "See required fields above.";
    isValid = false;
  } else {
    errors.maritalStatusItems = "";
  }

  return {
    isValid,
    errors,
  };
};

export default ValidateDemoGraphic;
