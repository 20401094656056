const validatePhysicalInjury = (value) => {
  let isValid = true;
  let errors = {};

  if (value.firstSymptomsNA !== true && !value.firstSymptoms.trim()) {
    errors.firstSymptoms = "See required fields above.";
    isValid = false;
  } else {
    errors.firstSymptoms = "";
  }

  if (value.firstTreatmentNA !== true && !value.firstTreatment.trim()) {
    errors.firstTreatment = "See required fields above.";
    isValid = false;
  } else {
    errors.firstTreatment = "";
  }

  if (value.restYourTreatmentNA !== true && !value.restYourTreatment.trim()) {
    errors.restYourTreatment = "See required fields above.";
    isValid = false;
  } else {
    errors.restYourTreatment = "";
  }

  if (value.doctorsListNA !== true && !value.doctorsList.trim()) {
    errors.doctorsList = "See required fields above.";
    isValid = false;
  } else {
    errors.doctorsList = "";
  }

  if (!value.receivedSurgery.trim()) {
    errors.receivedSurgery = "See required fields aboveS.";
    isValid = false;
  } else {
    errors.receivedSurgery = "";
  }

  if (value.surgeryListNA !== true && !value.surgeryList.trim()) {
    errors.surgeryList = "See required fields above.";
    isValid = false;
  } else {
    errors.surgeryList = "";
  }

  if (value.medicationListNA !== true && !value.medicationList.trim()) {
    errors.medicationList = "See required fields above.";
    isValid = false;
  } else {
    errors.medicationList = "";
  }

  if (!value.treatmentsHelped.trim()) {
    errors.treatmentsHelped = "See required fields aboveS.";
    isValid = false;
  } else {
    errors.treatmentsHelped = "";
  }

  if (!value.stillWorking.trim()) {
    errors.stillWorking = "See required fields aboveS.";
    isValid = false;
  } else {
    errors.stillWorking = "";
  }

  if (value.leavingReasonNA !== true && !value.leavingReason.trim()) {
    errors.leavingReason = "See required fields above.";
    isValid = false;
  } else {
    errors.leavingReason = "";
  }

  return {
    isValid,
    errors,
  };
};

export default validatePhysicalInjury;
