/** @format */

import React, { useState } from "react";
import moment from "moment";
export const GlobalContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [demographicInformation, setDemographicInformation] = useState({
    checkedEthnicityItems: [],
    radioSexItem: "",
    radioPreferPronounItem: "",
    maritalStatusItems: "",
    fullName: "",
    birth: moment(),
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    pregnant: null,
  });

  const [employmentInjuryPhysical, setEmploymentInjuryPhysical] = useState({
    currentEmployerName: "",
    businessNature: "",
    jobBeganDate: "",
    jobLastDate: "",
    employmentDuty: "",
    startedJobTitle: "",
    currentTitle: "",
    typicalWorkSchedule: "",
    salary: "",
    hourlyRate: "",
    overtimeRate: "",
    likeJob: "",
    notLikeJob: "",
    radioPhysicalConditionBeforeInjuryItem: "",
    radioMentalConditionBeforeInjuryItem: "",
    radioEmotionalSymptomsBeforeInjuryItem: "",
    describeMedicalConditionNA: false,
    describeMedicalCondition: "",
    injuryDate: "",
    describeInjuryOccurred: "",
    radioMedicationsBeforeInjuryItem: "",
    radioMedicationsNameBeforeInjuryItem: "",
    radioDisabilityConnectionClaimItem: "",
    currentDisability: "",
    radioContinuedWorkingItem: "",
    radioCurrentlyWorkingItem: "",
    radioConflictsItem: "",
    conflictsCount: "",
    eachConflicts: "",
    conflictsRank: "",
    conflictsRate: "",
    relationShipLikeManagement: "",
    immediateSupervisorName: "",
    relationshipImmediateSupervisor: "",
    explainSuperVisorReason: "",
    performanceAppraisals: "",
    explainPerformanceAppraisals: "",
    relationshipCoWorkers: "",
    explainRelationshipCoWorkers: "",
    verbalWarning: "",
    verbalWarningDateReason: "",
    lastStraw: "",
    explainLastStraw: "",
  });

  const [currentEmployer, setCurrentEmployer] = useState({
    currentlyWorkEmployerInjury: "",
    currentlyWorkEmployerName: "",
    currentlyWorkNatureBusiness: "",
    currentlyWorkJobTitle: "",
    currentlyWorkJobDuties: "",
    currentlyWorkJobBeganDate: "",
    currentlyWorkSchedule: "",
    currentlyWorkSalary: "",
    currentlyWorkLikeThisJob: "",
  });

  const [physicalInjury, setPhysicalInjury] = useState({
    firstSymptomsNA: false,
    firstSymptoms: "",
    firstTreatmentNA: false,
    firstTreatment: "",
    restYourTreatmentNA: false,
    restYourTreatment: "",
    doctorsListNA: false,
    doctorsList: "",
    receivedSurgery: "",
    surgeryListNA: false,
    surgeryList: "",
    medicationListNA: false,
    medicationList: "",
    treatmentsHelped: "",
    stillWorking: "",
    leavingReasonNA: false,
    leavingReason: "",
  });

  const [chiefComplaint, setChiefComplaint] = useState({
    mostBothered: "",
    currentlyExperiencingSymptom: [],
    currentEpisodeDate: "",
    specificStressfulSymptom: "",
    specificStressfulEvent: "",
    stressFollowing: [],
  });

  const [longitudinalHistory, setLongitudinalHistory] = useState({
    emotionEpisodeBegan: "",
    emotionSymptom: "",
    mostWorstSymptom: "",
    emotionalSymptomBecome: "",
    feelEmotion: "",
    explainFeelEmotion: "",
    depressiveSymptom: "0",
    anxietySymptom: "0",
    postTraumaSymptom: "0",
    compareEmotionalSymptom: "",
    symptomsAffectedJob: "",
    describeSymptomsAffectedJob: "",
    emotionalSymptomDate: "",
  });

  const [PHQ9, setPHQ9] = useState({
    interestThing: "",
    previouslyEnjoyable: "",
    feelingDepressed: "",
    depressiveSymptomsImproved: "",
    oftenFeelDepressed: "",
    experienceDepression: "",
    troubleFallingAsleep: "",
    fallAsleepLong: "",
    wakeUpTimes: "",
    stayAwakeLong: "",
    awakeSleepReason: [],
    totalSleepTimes: "",
    feelingEnergy: "",
    poorAppetite: "",
    recentlyWeightPounds: "",
    weightGainLostLong: "",
    yourselfFeelingBad: "",
    troubleConCentratingThing: "",
    fidgetyMoving: "",
    betterOffDeadYourself: "",
    deadWishWakeUp: "",
    killingYourself: "",
    killMethod: "",
    actingIntention: "",
    killIntentionCarryout: "",
    preparedAnythingEndYourlife: "",
    hurtingAnyone: "",
    currentDepressiveSymptoms: "0",
    phqScore: "0",
    fallASleep: "",
  });

  const [GAD7, setGAD7] = useState({
    feelingNervous: "",
    feltAnxiousLong: "",
    feelAnxiousOften: "",
    stopControlWorring: "",
    worringDifferentThing: "",
    worringThing: [],
    describeWorringThing: "",
    specificAnxietyWorse: "",
    troubleRelaxing: "",
    restlessSitHard: "",
    easilyAnnoyed: "",
    feelingAfraidAwfulThing: "",
    currentAnxietySymptoms: "0",
    panicAttacks: "",
    panicPhysicalSymptoms: [],
    panicAttacksLastLong: "",
    panicAttacksList: "",
    panicOccur: "",
    panicAttacksSpontaneous: "",
    pastTraumaticEvents: "",
    traumaticEventExperience: [],
    describeTraumaticExperience: "",
    gadScore: "0",
  });

  const [PCL5, setPCL5] = useState({
    stressfulExperienceMemories: "",
    stressfulExperience: "",
    disturbingDreamsOccur: "",
    suddenlyStressfulExperience: "",
    veryUpsetStressfulExperience: "",
    strongPhysicalReactionStressfulExperience: "",
    avoidingMemories: "",
    avoidingExternalReminders: "",
    describeSituations: "",
    avoidActivities: "",
    troubleStressfulExperience: "",
    strongNegativeBeliefs: "",
    stressfulExperienceBlaming: "",
    strongNegativefeelings: "",
    lossInterestActivity: "",
    feelingDistantPeople: "",
    troubleExperiencePositiveFeeling: "",
    irritableBehavior: "",
    manyRisksThing: "",
    beingWatchful: "",
    easilyStartled: "",
    difficultyConcentrating: "",
    troubleFallingAsleep: "",
    currentRelatedSymptoms: "0",
    PCLScore: "0",
  });

  const [globalCurrentTreatment, setGlobalCurrentTreatment] = useState({
    currentlyPsychiatricMedications: "",
    medicationList: "",
    medicationLong: "",
    medicationReason: [],
    describeMedicationReason: "",
    medicationsEffectYourCondition: [],
    medicationAsPrescribed: "",
    experiencedSideEffects: [],
    describeSideEffect: "",
    recentTreatmentProvider: "",
    currentlyPsychotherapyTreatment: "",
    medicationsEffectOther: "",
    recentPsychotherapyBegin: "",
    recentPsychotherapySession: "",
    psychotherapySessionsDate: "",
    psychotherapistTreatmentProvider: "",
  });

  const [globalPastHistory, setGlobalPastHistory] = useState({
    previouslyExperiencedSymptom: [],
    describeSymptoms: "",
    experienceMuchEnergy: "",
    sleptFewer4Hours: "",
    lackSleepEnergy: "",
    sleepFewer: "",
    mood: "",
    describeMood: "",
    alcoholSubstances: "",
    experienceFollowing: [],
    harmKillYourSelf: "",
    highEnergyTime: "",
    emotionalSymptomsRelationShip: "",
    firstFeelDepressedNA: false,
    firstFeelDepressed: "",
    feelHighLevelAnxietyNA: false,
    feelHighLevelAnxiety: "",
    pastPsychiatricMedicationOther: "",
    diagnosedMentalHealth: [],
    describeHealthCondition: "",
    hospitalizedDate: "",
    otherMedications: "",
    pastMedicationName: "",
    startedMedicationDate: "",
    stopedMedicationDate: "",
    pastPsychiatricMedication: [],
    stopedPsychiatricMedicationsReason: [],
    prescribeThisMedication: "",
    prescribeThisMedicationNameDateNA: false,
    prescribeThisMedicationNameDate: "",
    whatClinicWorkedNA: false,
    whatClinicWorked: "",
    otherPsychiatristsNA: false,
    otherPsychiatrists: "",
    thisPsychiatristSeeDateNA: false,
    thisPsychiatristSeeDate: "",
    attendedSessionsPsychiatrist: "",
    previouslyReceivedPsychotherapy: "",
    receivedPsychotherapyBegin: "",
    receivedPsychotherapyLong: "",
    attendedSessionsPsychotherapy: "",
    pastPsychotherapistsDate: "",
    otherPsychotherapyTreatmentList: "",
    admittedPsychiatricHospital: "",
    psychiatricHospitalizationReason: [],
    receivedTreatment: "",
    admittedHospitalName: "",
    hospitalizedLong: "",
    suicidalIdeation: "",
    suicideAttempt: "",
    attemptedSuicideTimes: "1",
    suicideAllMethods: "",
    attemptedSuicideDate: "",
    otherPsychiatricSymptoms: "",
    describeOtherPsychiatricSymptoms: "",
    otherPsychotherapyTreatment: "",
    describeOtherPsychotherapyTreatment: "",
    evaluatedOtherwisePsychiatrists: "",
    evaluationReason: "",
    evaluationPerformed: "",
    evaluationOccur: "",
    physicalAltercations: "",
    physicialAltercationsMany: "",
  });

  const [globalMedicalHistory, setGlobalMedicalHistory] = useState({
    diagnosedHealthcareProvider: [],
    otherMedicalCondition: "",
    pregnantPlanning: "",
    plannedPregnancyProvider: "",
    physicalHealthMedicationsListsNA: false,
    physicalHealthMedicationsLists: "",
    surgeries: "",
    surgeriesDateList: "",
    medicationsSideEffectNA: false,
    medicationsSideEffect: "",
    medicationsSideEffectList: "",
    futureMedicalPlan: "",
    futureMedicalPlanList: "",
    currentPrimarycarePractitionerNA: false,
    currentPrimarycarePractitioner: "",
    pastprimarycarePractitionerNA: false,
    pastprimarycarePractitioner: "",
    periodReceiveProvider: "",
    hospitalListEverBeenNA: false,
    hospitalListEverBeen: "",
    medicationsListTaking: "",
    allergiesMedication: "",
    allergiesList: "",
  });

  const [globalSubStanceUse, setGlobalSubStanceUse] = useState({
    followingSubstances: [],
    currentlySubstance: [],
    eachSubstanceList: [],
    eachSubstanceListStartedOld: [],
    toleranceFollowingSubstances: [],
    withdrawalFollowingSubstances: [],
    regardingAlcoholAnyFollowing: [],
    enrolledTreatmentProgram: "",
    completeTreatmentProgram: "",
    treatmentLastedDateFrom: "",
    treatmentLastedDateTo: "",
    remainedTreatmentClean: "",
    remainedTreatmentCleanLongest: "",
    previouslyDescribedPsychiatricClean: "",
    toleranceDefinedFollowing: [],
    withdrawalEitherFollowing: [],
    amountValue: "",
    eachSubstanceLast: [],
    eachSubstanceLastDate: "",
    cleanSoberLastedFrom: "",
    cleanSoberLastedTo: "",
    cleanSoberLongest: "",
  });

  const [globalFamilyHistory, setGlobalFamilyHistory] = useState({
    familyPsychiatricConditions: [],
    psychiatricConditionsList: "",
    psychiatricConditionsTreatment: [],
    familyAttemptedSuicide: "",
  });

  const [globalRelationshipHistory, setGlobalRelationshipHistory] = useState({
    currentlyIntimateRelationship: "",
    currentlyMarried: "",
    currentlyUnit: "weeks",
    currentRelationshipInvolve: "",
    describeIntimateRelationship: [],
    PartnerOccupation: "",
    marriedNumber: "",
    intimateRelationshipTimes: "1",
    longTermRelationshipsLast: "",
    reasonPreviousRelationships: "",
    domesticViolence: "",
    haveChildren: "",
    childrenNumberAndAge: "",
    childrenDoingSchool: "",
    relationshipChildren: "",
    childrenHealthIssues: "",
    sufferPsychiatricConditions: "",
    stressfulPsychiatricConditions: "",
  });

  const [globalEmploymentHistory, setGlobalEmploymentHistory] = useState({
    currentEmploymentStatus: "",
    employerName: "",
    employmentTitle: "",
    jobDuties: "",
    difficultyJobDuties: "",
    pastImmediatelyEmployerName: "",
    pastWorkplaceInjuries: "",
    injuriesOccurTime: "",
    injuryNature: "",
    workerCompensationClaim: "",
    placedDisability: "",
    disabilityDates: "",
    receivedNegativeWork: "",
    currentSourcesIncome: [],
    otherEmploymentList: "",
    workEvaluationsExplain: "",
    employerList: [
      {
        employer: "",
        jobTitle: "",
        datesOfEmployment: "",
        reasonForLeaving: "",
      },
    ],
  });

  const [globalEducationHistory, setGlobalEducationHistory] = useState({
    highestLevelEducation: "",
    currentlyEnrolledEducation: "",
    mostlyReceiveGrade: [],
    learningDisability: "",
    describeLearningDifficulties: "",
    graduateHighSchool: "",
    graduateOnTime: "",
    goToCollege: "",
    completeYourDegree: "",
    collegeName: "",
    collegeMajor: "",
  });

  const [globalSocialHistory, setGlobalSocialHistory] = useState({
    barriersReceivingHealthcare: "",
    selectbarriersHealthcare: [],
    describeCurrentLivingSituation: [],
    livesYourHome: [],
    residedCurrentHomeLong: "",
    ownYourHome: "",
    describeAdditionalStressors: "",
    presentTimeDanger: "",
    describeFeelDanger: "",
    allStressorsPastYearNA: false,
    allStressorsPastYear: "",
    stressorsAffect: "",
    eachStressorsAffect: "",
    otherStressorsBesides: "",
    explainAllStressors: "",
    affectEmotionalSymptoms: "",
    eachAffectEmotionalSymptoms: "",
    otherStressorsExperience: "",
    explainStressorsExperience: "",
    currentLocation: "",
  });

  const [globalCriminalHistory, setGlobalCriminalHistory] = useState({
    arrested: "",
    charges: "",
    everIncarcerated: "",
    currentlyParole: "",
    arrestedDate: "",
  });

  const [globalViolenceHistory, setGlobalViolenceHistory] = useState({
    physicalAltercations: "",
    altercationsTimes: "",
    circumstancesSurrounding: "",
    thoughtsHurtAnyone: "",
    explainAccomplishingHurt: "",
    victimViolence: "",
    currentlyDangerViolence: "",
  });

  const [globalMilitaryHistory, setGlobalMilitaryHistory] = useState({
    enrolledMilitary: "",
    branchMilitary: "",
    militaryDatesFrom: moment(),
    militaryDatesTo: moment(),
    militaryJob: "",
    dischargeStatus: "",
  });

  const [globalCurrentDailyActivities, setGlobalCurrentDailyActivities] =
    useState({
      awakenTimeWorkDays: "",
      awakenTimeNotWorkDays: "",
      goToBed: "",
      fallAsleepTime: "",
      do6am: "",
      do8am: "",
      do10am: "",
      do12pm: "",
      do2pm: "",
      do4pm: "",
      do6pm: "",
      do8pm: "",
      do10pm: "",
      do12p6am: "",
      leisureActivities: "",
      dailyLivingFollowing: [],
      difficultAmount: [],
      anyActivitiesListBefore: "",
      troubleFollowing: [],
    });

  const [globalDevelopmentalHistory, setGlobalDevelopmentalHistory] = useState({
    bornPlace: "",
    primarilyRaised: "",
    raisedChilhood: "",
    describeRelationshipPerson: "",
    primaryAdultsRelationship: "",
    haveSiblings: "",
    siblingsMany: "",
    siblingsRaised: "",
    relationshipSiblings: [],
    experienceAbuseChildhood: [],
    parentsMarried: "",
    parentsRemainMarried: "",
    parentsDivorce: "",
    yourOldParentsDivorced: "",
    motherWork: "",
    motherJob: "",
    motherStillWork: "",
    motherCurrentLiving: "",
    diedMotherOld: "",
    whatDiedMother: "",
    fatherWork: "",
    fatherJob: "",
    fatherStillWork: "",
    fatherCurrentLiving: "",
    diedFatherOld: "",
    whatDiedFather: "",
    bestDescribesSocialLifeChild: "",
    relationshipPrimaryAdults: [],
    enjoyActivitiesChild: "",
  });

  const [globalAdditionalInformation, setGlobalAdditionalInformation] =
    useState({
      evaluatingClinicianNA: false,
      evaluatingClinician: "",
      yourAdditionalInformationNA: false,
      yourAdditionalInformation: "",
    });

  return (
    <GlobalContext.Provider
      value={{
        demographicInformation,
        setDemographicInformation,
        employmentInjuryPhysical,
        setEmploymentInjuryPhysical,
        currentEmployer,
        setCurrentEmployer,
        physicalInjury,
        setPhysicalInjury,
        chiefComplaint,
        setChiefComplaint,
        longitudinalHistory,
        setLongitudinalHistory,
        PHQ9,
        setPHQ9,
        GAD7,
        setGAD7,
        PCL5,
        setPCL5,
        globalCurrentTreatment,
        setGlobalCurrentTreatment,
        globalPastHistory,
        setGlobalPastHistory,
        globalSubStanceUse,
        setGlobalSubStanceUse,
        globalMedicalHistory,
        setGlobalMedicalHistory,
        globalFamilyHistory,
        setGlobalFamilyHistory,
        globalRelationshipHistory,
        setGlobalRelationshipHistory,
        globalEmploymentHistory,
        setGlobalEmploymentHistory,
        globalEducationHistory,
        setGlobalEducationHistory,
        globalSocialHistory,
        setGlobalSocialHistory,
        globalCriminalHistory,
        setGlobalCriminalHistory,
        globalViolenceHistory,
        setGlobalViolenceHistory,
        globalMilitaryHistory,
        setGlobalMilitaryHistory,
        globalCurrentDailyActivities,
        setGlobalCurrentDailyActivities,
        globalDevelopmentalHistory,
        setGlobalDevelopmentalHistory,
        globalAdditionalInformation,
        setGlobalAdditionalInformation,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
